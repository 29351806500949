import React, { useState, useEffect } from "react";
import { NavLink, useHistory } from "react-router-dom";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";

function SuccessfulLoginModal() {
  const history = useHistory();
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    // Show the modal when showAlert becomes true
    setModalOpen(true);
  }, []);

  const handleClose = () => {
    // Close the modal and redirect to the sign-in page
    setModalOpen(false);
    history.push("/auth/sign-in");
  };

  return (
    <Modal isOpen={modalOpen} toggle={handleClose} className="vh-100" backdrop="static">
      <ModalHeader toggle={handleClose}>Registration Successful</ModalHeader>
      <ModalBody>
        <p>Check your email to verify your account</p>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={handleClose}>
          Go to Sign In
        </Button>
      </ModalFooter>
    </Modal>
  );
}

export default SuccessfulLoginModal;
