import React, { useEffect } from 'react';

const TawkToWidget = () => {
  useEffect(() => {
    const tawkToScript = document.createElement('script');
    console.log(tawkToScript)
    
    tawkToScript.type = 'text/javascript';
    tawkToScript.innerHTML = `
      var Tawk_API=Tawk_API||{}, Tawk_LoadStart=new Date();
      (function(){
        var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
        s1.async=true;
        s1.src='https://embed.tawk.to/65713ee1bfb79148e59ae787/default';
        s1.charset='UTF-8';
        s1.setAttribute('crossorigin','*');
        s0.parentNode.insertBefore(s1,s0);
      })();
    `;
    document.head.appendChild(tawkToScript);
  }, []);

  return <div id="tawkto-widget" />;
};

export default TawkToWidget;
