import { Box, SimpleGrid } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { Table } from "reactstrap";
import Card from "components/card/Card.js";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import dummy from "./dummy.json";
import Alert from "middleware/alert";
import { makeApiRequest } from "middleware/api";

export default function ProfitRecords() {
  const [investment, setInvestment] = useState([]);

  let token = localStorage.getItem("token");

  useEffect(async () => {
    try {
      let response = await makeApiRequest("/profits", "GET", null, {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      });

      if (response.status === 200) {
        const responseData = response.data.data;
        // console.log(responseData);

        setInvestment(responseData);
      }
    } catch (error) {
      // console.log(error);
    }
  }, []);

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <SimpleGrid
        mb="20px"
        columns={{ sm: 1, md: 1, lg: 1 }}
        spacing={{ base: "20px", xl: "20px" }}
      >
        <Card mb={{ base: "0px", "2xl": "20px" }}>
          <h2 className="h2 text-center">Profit Records</h2>
          <div className="table-responsive">
            <Table className="table table-bordered table-striped bg-dark">
              <thead>
                <tr>
                  <th>Plan</th>
                  <th>Amount</th>
                  <th>Date</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {investment.map((item, key) => {
                  let statusBadge = "";

                  switch (item.status) {
                    case "completed":
                      statusBadge = "badge text-bg-success";
                      break;
                    case "pending":
                      statusBadge = "badge text-bg-warning";
                      break;
                    case "rejected":
                      statusBadge = "badge text-bg-danger";
                      break;
                    default:
                      statusBadge = "badge text-bg-info"; // Default color if the status is not recognized
                  }

                  return (
                    <tr key={key}>
                      <td>{item.plan}</td>
                      <td>${item.amount}</td>
                      <td>{item.date}</td>
                      <td>
                        <div className={statusBadge}>{item.status}</div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        </Card>
      </SimpleGrid>
    </Box>
  );
}
