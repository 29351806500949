import React, { useState, useEffect } from "react";
import { NavLink, useLocation, Link } from "react-router-dom";
import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  InputRightElement,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { FormGroup, Input, InputGroup } from "reactstrap";
import { FaUser, FaUserPlus, FaLock, FaMoneyBill } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import Alert from "middleware/alert";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { RiEyeCloseLine } from "react-icons/ri";
import { makeApiRequest } from "middleware/api";
import logo from "assets/img/logo-black.png";

function SignIn() {
  const textColor = useColorModeValue("navy.700", "white");
  const textColorSecondary = "gray.400";
  const textColorDetails = useColorModeValue("navy.700", "secondaryGray.600");
  const textColorBrand = useColorModeValue("brand.500", "white");
  const brandStars = useColorModeValue("brand.500", "brand.400");
  const [show, setShow] = useState(false);
  const handleClick = () => setShow(!show);

  const [showAlert, setShowAlert] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const [status, setStatus] = useState("error");
  const [message, setMessage] = useState("");

  function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }

  let query = useQuery();
  let token = query.get("token");

  useEffect(() => {
    if (token) {
      localStorage.setItem("token", token);
      window.location.href = "/dashboard/overview";
    }
  }, [token]);

  useEffect(() => {
    // Hide the alert after 2 seconds
    const timer = setTimeout(() => {
      setShowAlert(false);
      if (status == "success") {
        window.location.href = "/dashboard/overview";
      }
    }, 2000);

    return () => {
      clearTimeout(timer);
    };
  }, [showAlert]);

  const handleSignInSubmit = async (event) => {
    event.preventDefault();
    setDisableButton(true);

    // Handle form submission logic here
    const formData = new FormData(event.target);
    const signInData = Object.fromEntries(formData);

    try {
      let response = await makeApiRequest("/login", "POST", signInData);

      setShowAlert(true);

      if (response?.status === 200) {
        const responseData = response.data;
        setStatus("success");
        setMessage("Login Successful");

        localStorage.setItem("token", responseData.data.token);
      } else {
        setStatus("error");
        if (response?.response?.status === 422) {
          let errors = response.response.data.errors;

          errors?.email && setMessage(errors?.email[0]);
          errors?.username && setMessage(errors?.username[0]);
          errors?.password && setMessage(errors?.password[0]);
        } else if (response?.response?.status === 401) {
          setMessage("Wrong Login Details");
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setDisableButton(false);
    }
  };

  return (
    // ... rest of the code ...

    <Flex
      maxW={{ base: "100%", md: "max-content" }}
      w="90vw"
      mx="auto"
      me="auto"
      h="100%"
      alignItems="start"
      justifyContent="center"
      mb={{ base: "30px", md: "60px" }}
      px={{ base: "0px", md: "0px", sm: "0px" }}
      mt={{ base: "40px", md: "14vh" }}
      flexDirection="column"
    >
      <div className="d-flex justify-content-center w-100 auth-logo-container">
        <a href="https://tophedgefinance.ltd">
          <img src={logo} className="img-fluid auth-logo" alt="logo" />
        </a>
      </div>

      
      <Flex
        zIndex="2"
        direction="column"
        w={{ base: "100%", md: "420px" }}
        maxW="100%"
        background="transparent"
        borderRadius="15px"
        mx={{ base: "auto", lg: "unset" }}
        me="auto"
        mb={{ base: "20px", md: "auto" }}
      >
        <form className="auth-form" onSubmit={handleSignInSubmit}>
        <h3 className="text-dark text-center mb-lg-4 mb-3">Log In</h3>
          <FormControl>
            <FormLabel
              display="flex"
              ms="4px"
              fontSize="sm"
              fontWeight="600"
              color={textColor}
              mb="8px"
            >
              Email<Text color={brandStars}>*</Text>
            </FormLabel>
            
            <InputGroup>
              <div className="input-group-prepend">
                <span className="input-group-text">
                  <MdEmail height="100%" />
                </span>
              </div>
              <Input
                required={true}
                variant="auth"
                fontSize="sm"
                ms={{ base: "0px", md: "0px" }}
                type="email"
                name="username"
                placeholder="Enter Email Address"
                mb="24px"
                fontWeight="500"
                size="lg"
              />
            </InputGroup>
          </FormControl>
          <FormControl>
            <FormLabel
              ms="4px"
              fontSize="sm"
              fontWeight="600"
              color={textColor}
              display="flex"
            >
              Password<Text color={brandStars}>*</Text>
            </FormLabel>
            <InputGroup size="md">
              <div className="input-group-prepend">
                <span className="input-group-text">
                  <FaLock height="100%" />
                </span>
              </div>
              <Input
                required={true}
                fontSize="sm"
                placeholder="Enter Password"
                className="form-control"
                mb="24px"
                size="lg"
                type={show ? "text" : "password"}
                variant="auth"
                name="password"
              />
              <InputRightElement display="flex" alignItems="center" mt="1rem" me="0.8rem">
                <Icon
                  color={textColorSecondary}
                  _hover={{ cursor: "pointer" }}
                  as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                  onClick={handleClick}
                />
              </InputRightElement>
            </InputGroup>
          </FormControl>
          <Flex justifyContent="space-between" align="center" mb="24px">
            <FormControl display="flex" alignItems="center">
              <Checkbox
                id="remember-login"
                colorScheme="brandScheme"
                me="10px"
              />
              <FormLabel
                htmlFor="remember-login"
                mb="0"
                fontWeight="normal"
                color={textColor}
                fontSize="sm"
              >
                Keep me logged in
              </FormLabel>
            </FormControl>
            <NavLink to="/auth/forgot-password">
              <Text
                color={textColorBrand}
                fontSize="sm"
                w="124px"
                fontWeight="500"
              >
                Forgot password?
              </Text>
            </NavLink>
          </Flex>
          <Button
            type="submit"
            fontSize="sm"
            variant="brand"
            fontWeight="500"
            w="100%"
            h="50"
            mb="24px"
            disabled={disableButton}
          >
            Sign In
          </Button>
          <Flex
          flexDirection="column"
          justifyContent="center"
          alignItems="start"
          maxW="100%"
          mt="0px"
        >
          <Text color={textColorDetails} fontWeight="400" fontSize="14px">
            Not registered yet?
            <NavLink to="/auth/sign-up">
              <Text color={textColorBrand} as="span" ms="5px" fontWeight="500">
                Create an Account
              </Text>
            </NavLink>
          </Text>
        </Flex>
        </form>

        <Flex
          flexDirection="column"
          justifyContent="center"
          alignItems="start"
          maxW="100%"
          mt="0px"
        >
          {showAlert && (
            <Alert className="w-100 p-4" message={message} status={status} />
          )}
        </Flex>
        
      </Flex>
    </Flex>
  );
}

export default SignIn;
