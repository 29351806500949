import { Box, SimpleGrid } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import {
  Col,
  Row,
  Container,
  Form,
  FormGroup,
  Label,
  Input,
  Table,
  Button,
} from "reactstrap";
import Card from "components/card/Card.js";
// import wallets from "./dummy.json";
import Alert from "middleware/alert";
import { makeApiRequest } from "middleware/api";
import TawkToWidget from "contexts/TawkTo";

export default function Deposit() {
  const [walletPanels, setWalletPanels] = useState(false);
  const [wallets, setWallets] = useState([]);
  const [amountToDeposit, setAmountToDeposit] = useState("");
  const [depositMethod, setDepositMethod] = useState([]);
  const [selectedDepositMethod, setSelectedDepositMethod] = useState("");

  const [profile, setProfile] = useState({});

  const [showAlert, setShowAlert] = useState(false);
  const [status, setStatus] = useState("error");
  const [disableButton, setDisableButton] = useState(false);
  const [message, setMessage] = useState("");

  // const [investment, setInvestment] = useState(dummy);
  let token = localStorage.getItem("token");

  useEffect(async () => {
    try {
      let response = await makeApiRequest(
        "/get-deposit-method-list",
        "GET",
        null,
        {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        }
      );

      if (response.status === 200) {
        const responseData = response.data.data;
        // console.log(responseData);

        setDepositMethod(responseData);
      }
    } catch (error) {
      // console.log(error);
    }
  }, []);

  useEffect(async () => {
    try {
      let response = await makeApiRequest("/profile", "GET", null, {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      });

      if (response.status === 200) {
        const responseData = response.data.data;
        // console.log(responseData);

        setProfile(responseData);
      }
    } catch (error) {
      // console.log(error);
    }
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowAlert(false);
    }, 2000);

    return () => {
      clearTimeout(timer);
    };
  }, [showAlert]);

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    setWalletPanels(false);

    let data = {
      deposit_method_id: selectedDepositMethod,
      amount: amountToDeposit,
    };
    // console.log(data);

    try {
      let response = await makeApiRequest("/get-deposit-method", "POST", data, {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      });

      setShowAlert(true);

      if (response.status === 200) {
        setStatus("success");
        setMessage("Wallet Generation Successful");

        setWallets(response.data.data);
        setWalletPanels(true);
      } else {
        setStatus("error");

        if (response.response.status === 422) {
          response.response.data.errors.deposit_method_id &&
            setMessage(response.response.data.errors.deposit_method_id[0]);
          response.response.data.errors.amount &&
            setMessage(response.response.data.errors.amount[0]);
        } else if (response.response.status === 401) {
          setMessage(response.response.data.message);
        } else if (response.response.status === 400) {
          setMessage(response.response.data.message);
        }
      }
    } catch (error) {
      // console.log(error);
    }
  };

  const handleCopyAddress = (address) => {
    try {
      navigator.clipboard.writeText(address);
      alert("Address copied to clipboard!");
    } catch (error) {
      console.error("Failed to copy address: ", error);
    }
  };

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <SimpleGrid
        mb="20px"
        columns={{ sm: 1, md: 2, lg: 1 }}
        spacing={{ base: "20px", xl: "20px" }}
      >
        <Card className="min-vh-75" mb={{ base: "0px", "2xl": "20px" }}>
          <h2 className="h2 text-center">Deposit Funds to Your Wallet</h2>
          <div className="body py-3">
            <Row className="mx-auto justify-content-center">
              <Col md={6}>
                <div className="d-flex justify-content-center">
                  Account Balance:{" "}
                  {`$ ${
                    profile?.wallet_balance == undefined
                      ? "---"
                      : profile?.wallet_balance
                  }`}
                </div>
                <Form onSubmit={handleFormSubmit}>
                  <FormGroup>
                    <Label for="depositMethod">Select Deposit Method</Label>
                    <Input
                    className="form-control"
                      type="select"
                      id="depositMethod"
                      value={selectedDepositMethod}
                      onChange={(e) => setSelectedDepositMethod(e.target.value)}
                      required
                    >
                      <option value="">Select Deposit Method</option>
                      {depositMethod.map((method) => (
                        <option
                          key={method.id}
                          value={method.deposit_method_id}
                        >
                          {method.name} ({method.symbol})
                        </option>
                      ))}
                    </Input>
                  </FormGroup>

                  <FormGroup>
                    <Label for="amountToSend">Amount to Deposit</Label>
                    <Input
                      type="number"
                      id="amountToDeposit"
                      value={amountToDeposit}
                      onChange={(e) => setAmountToDeposit(e.target.value)}
                      placeholder="Enter amount to deposit"
                      required
                    />
                  </FormGroup>
                  <Button className="w-100" color="primary" type="submit">
                    Deposit
                  </Button>

                  {showAlert && (
                    <Alert
                      className="w-100 p-4"
                      message={message}
                      status={status}
                    />
                  )}
                </Form>
              </Col>
            </Row>
          </div>
        </Card>

        {walletPanels && (
          <>
            <div className="border-0 rounded-1 bg-info p-3">
              Pay to any of the uniquely generated addresses below and wait for
              instant automatic confirmation
            </div>
            <div className="table-responsive">
              <Table className="table table-bordered table-striped">
                <thead>
                  <tr>
                    <th>Token</th>
                    <th>Amount</th>
                    <th>Processing Time</th>
                    <th>Fees</th>

                    <th>Address</th>
                  </tr>
                </thead>
                <tbody>
                  {wallets?.map((item, key) => {
                    return (
                      <tr key={key}>
                        <td>
                          {item.name} ({item.symbol})
                        </td>
                        <td className="text-warning">${item.amount}</td>
                        <td>{item.processing_time}</td>
                        <td>{item.fees}</td>

                        <td className="d-lg-flex">
                          <span className="text-warning">{item.address}</span>

                          <Button
                            size="sm"
                            color="info"
                            className="ms-lg-2"
                            onClick={() => handleCopyAddress(item.address)}
                          >
                            Copy
                          </Button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>
          </>
        )}
      </SimpleGrid>
      <TawkToWidget />
    </Box>
  );
}
