import { Box, SimpleGrid } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import {
  Col,
  Row,
  Container,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import Card from "components/card/Card.js";
import plansJson from "./plans.json";
import Alert from "middleware/alert";
import { makeApiRequest } from "middleware/api";

export default function NewPlans() {

  let test = [
    {
      "signal_id": "1",
      "signal_name": "Bitcoin cash",
      "account_type": "copy trading"
    },
    {
      "signal_id": "2",
      "signal_name": "Bitcoin",
      "account_type": "copy trading"
    },
    {
      "signal_id": "3",
      "signal_name": "Ethereum",
      "account_type": "copy trading"
    },
  ]
  const [plans, setPlans] = useState([]);
  const [signals, setSignals] = useState([]);
  const [currentSignal, setCurrentSignal] = useState("");
  const [isModalOpen, setModalOpen] = useState(false);

  const [amount, setAmount] = useState("");
  const [currentForm, setCurrentForm] = useState({
    id: "",
    key: "",
  });

  const [showAlert, setShowAlert] = useState(false);
  const [status, setStatus] = useState("error");
  const [disableButton, setDisableButton] = useState(false);
  const [message, setMessage] = useState("");

  let token = localStorage.getItem("token");

  useEffect(async () => {
    const initialAmount = Array.from({ length: plans.length }, () => "");
    setAmount(initialAmount);
    setCurrentSignal(initialAmount);
  }, [plans]);


  useEffect(() => {
    const timer = setTimeout(() => {
      setShowAlert(false);

      if (status == "success") {
        window.location.href = "/user/investment-history";
      }
    }, 4000);

    return () => {
      clearTimeout(timer);
    };
  }, [showAlert]);

  useEffect(async () => {
    try {
      let response = await makeApiRequest("/investment-plans", "GET", null, {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      });

      if (response.status === 200) {
        const responseData = response.data.data;
        // console.log(responseData);

        setPlans(responseData);
      }
    } catch (error) {
      // console.log(error);
    }


    try {
      let response = await makeApiRequest("/signals", "GET", null, {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      });

      if (response.status === 200) {
        const responseData = response.data.data;
        console.log(responseData);

        setSignals(responseData);
      }
    } catch (error) {
      // console.log(error);
    }
  }, []);

  const handleForm = (event, id, key) => {
    event.preventDefault();


    if(amount[key] == ""){
      alert("The Amount field must be filled!")
    }
    else if(currentSignal[key] == ""){
      alert("Please choose a Trading Signal!")
    }
    else if (amount[key] != "") {
      setCurrentForm({
        ...currentForm,
        id: id,
        key: key,
      });

      setModalOpen(true);
    }

    
  };

  const handleFormSubmit = async (event, id, key) => {
    event.preventDefault();

    let data = {
      plan_id: id,
      amount: amount[key],
      signal_id: currentSignal[key],
    };


    try {
      let response = await makeApiRequest("/create-investment", "POST", data, {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      });

      setShowAlert(true);

      if (response.status === 200) {
        setStatus("success");
        setMessage("Investment initiated successful");
      } else {
        setStatus("error");

        if (response.response.status === 422) {
          response.response.data.errors.plan_id &&
            setMessage(response.response.data.errors.plan_id[0]);
          response.response.data.errors.amount &&
            setMessage(response.response.data.errors.amount[0]);
        } else if (response.response.status === 401) {
          setMessage(response.response.data.message);
        } else if (response.response.status === 400) {
          setMessage(response.response.data.message);
        }
      }
    } catch (error) {
      // console.log(error);
    }
  };

  const toggleModal = (plan) => {
    setModalOpen(!isModalOpen);
  };

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <SimpleGrid
        mb="20px"
        columns={{ base: 1, md: 2, lg: 3, "2xl": 6 }}
        spacing={{ base: "20px", xl: "20px" }}
      >
        {plans.map((item, key) => {
          return (
            <Card
              key={key}
              className="min-vh-50"
              mb={{ base: "0px", "2xl": "20px" }}
            >
              <p className="h4 text-warning font-weight-bold">{item.name}</p>

              <h2 className="h2 text-center py-3">${item.min_amount}</h2>
              <div className="body py-1">
                <Row className="py-1">
                  <Col md={9}>Minimum Possible Deposit:</Col>
                  <Col className="d-flex justify-content-end" md={3}>
                    ${item.min_amount}
                  </Col>
                </Row>
                <Row className="py-1">
                  <Col md={9}>Maximum Possible Deposit:</Col>
                  <Col className="d-flex justify-content-end" md={3}>
                    ${item.max_amount}
                  </Col>
                </Row>
                <Row className="py-1">
                  <Col md={9}>Minimum Return:</Col>
                  <Col className="d-flex justify-content-end" md={3}>
                    ${item.min_return}
                  </Col>
                </Row>
                <Row className="py-1">
                  <Col md={9}>Maximum Return:</Col>
                  <Col className="d-flex justify-content-end" md={3}>
                    ${item.max_return}
                  </Col>
                </Row>
                <Row className="py-1">
                  <Col md={9}>Gift Bonus:</Col>
                  <Col className="d-flex justify-content-end" md={3}>
                    ${item.bonus}
                  </Col>
                </Row>
                <Row className="py-1">
                  <Col md={9}>Duration:</Col>
                  <Col className="d-flex justify-content-end" md={3}>
                    {item.duration_label}
                  </Col>
                </Row>
                <Row className="pt-4">
                  <Col md={12}>
                    <form>
                      <FormGroup>
                        <Label className="text-center" for="amount">
                          Trading Signal
                        </Label>
                        <Input
                        className="form-control"
                          type="select"
                          id="signal"
                          name="signal"
                          value={currentSignal[key]}
                          onChange={(e) => {
                            const newSignal = [...currentSignal];
                            newSignal[key] = e.target.value;
                            setCurrentSignal(newSignal);
                          }}
                         
                          required={true}
                        >
                          <option disabled={true} value="">---Select Signal to Trade---</option>
                          {signals?.map((data, key) => {
                            return <option value={data.signal_id}>{data.signal_name}</option>
                          })}

                        </Input>
                      </FormGroup>
                      <FormGroup>
                        <Label className="text-center" for="amount">
                          Amount to Invest
                        </Label>
                        <Input
                          type="number"
                          id="amount"
                          name="amount"
                          min={item.min_amount}
                          max={item.max_amount}
                          value={amount[key]}
                          onChange={(e) => {
                            const newAmount = [...amount];
                            newAmount[key] = Number(e.target.value);
                            setAmount(newAmount);
                          }}
                          placeholder="Enter Amount to invest"
                          required={true}
                        />
                      </FormGroup>
                      <Button
                        className="w-100"
                        color="success"
                        type="button"
                        onClick={(event) =>
                          handleForm(event, item.plan_id, key)
                        }
                      >
                        Subscribe
                      </Button>
                    </form>
                  </Col>
                </Row>
              </div>
            </Card>
          );
        })}
      </SimpleGrid>
      {showAlert && (
        <Alert className="w-100 p-4" message={message} status={status} />
      )}

      <Modal isOpen={isModalOpen} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>Confirm Subscription</ModalHeader>
        <ModalBody>
          Are you sure you want to subscribe to{" "}
          <span className="text-warning"> {plans[currentForm.key]?.name}</span>{" "}
          with <span className="text-danger">${amount[currentForm.key]}</span>?
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggleModal}>
            Cancel
          </Button>
          <Button
            color="primary"
            onClick={(event) => {
              toggleModal();
              handleFormSubmit(event, currentForm.id, currentForm.key);
            }}
          >
            Confirm
          </Button>{" "}
        </ModalFooter>
      </Modal>
    </Box>
  );
}
