import {
  Avatar,
  Box,
  Flex,
  FormLabel,
  Icon,
  Select,
  SimpleGrid,
  useColorModeValue,
} from "@chakra-ui/react";
// Assets
import Usa from "assets/img/dashboards/usa.png";
// Custom components
import MiniCalendar from "components/calendar/MiniCalendar";
import MiniStatistics from "components/card/MiniStatistics";
import IconBox from "components/icons/IconBox";
import React, { useState, useEffect } from "react";
import {
  MdAccountBalanceWallet,
  MdAttachMoney,
  MdMonetizationOn,
  MdPeople,
  MdTrendingUp,
  MdCreditCard,
  MdMoneyOff,
  MdBusiness,
} from "react-icons/md";

import PieCard from "views/admin/default/components/PieCard";
import TradingViewWidget from "react-tradingview-widget";
import { makeApiRequest } from "middleware/api";

export default function UserReports() {
  const [profile, setProfile] = useState({});
  let token = localStorage.getItem("token");

  useEffect(async () => {
    // console.log(token);
    try {
      let response = await makeApiRequest("/profile", "GET", null, {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      });

      if (response.status === 200) {
        const responseData = response.data.data;
        setProfile(responseData);
      }
    } catch (error) {
      // console.log(error);
    }
  }, []);

  // Chakra Color Mode
  const brandColor = useColorModeValue("#283552", "white");
  const boxBg = useColorModeValue("secondaryGray.300", "#fff");

  return (
    <>
      <Box
        pt={{ base: "130px", md: "80px", xl: "80px" }}
        px={{ base: "40px", md: "80px", xl: "80px" }}
      >
        <SimpleGrid
          columns={{ base: 1, md: 2, lg: 2, "2xl": 2 }}
          gap="5px"
          mb="20px"
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
        >
          <div className="div">
            <h6 className="text-warning">
              Welcome,{" "}
              {`${
                profile?.full_name == undefined ? "---" : profile?.full_name
              }`}
            </h6>
          </div>
        </SimpleGrid>
      </Box>
      <Box>
        <SimpleGrid
          columns={{ base: 2, md: 2, lg: 4, "2xl": 6 }}
          gap="20px"
          mb="20px"
          mt="10px"
        >
          <MiniStatistics
            startContent={
              <IconBox
                w="30px"
                h="30px"
                bg={boxBg}
                icon={
                  <Icon
                    w="22px"
                    h="22px"
                    as={MdAccountBalanceWallet}
                    color={brandColor}
                  />
                }
              />
            }
            name="Account Balance"
            value={`$${
              profile?.wallet_balance == undefined
                ? "---"
                : profile?.wallet_balance
            }`}
          />
          <MiniStatistics
            startContent={
              <IconBox
                w="30px"
                h="30px"
                bg={boxBg}
                icon={
                  <Icon
                    w="22px"
                    h="22px"
                    as={MdAccountBalanceWallet}
                    color={brandColor}
                  />
                }
              />
            }
            name="Invested"
            value={`$${
              profile?.total_investment_amount == undefined
                ? "---"
                : profile?.total_investment_amount
            }`}
          />
          <MiniStatistics
            startContent={
              <IconBox
                w="30px"
                h="30px"
                bg={boxBg}
                icon={
                  <Icon
                    w="22px"
                    h="22px"
                    as={MdAttachMoney}
                    color={brandColor}
                  />
                }
              />
            }
            name="Total Profits"
            value={`$${
              profile?.total_earning == undefined
                ? "---"
                : profile?.total_earning
            }`}
          />
          <MiniStatistics
            startContent={
              <IconBox
                w="30px"
                h="30px"
                bg={boxBg}
                icon={
                  <Icon
                    w="22px"
                    h="22px"
                    as={MdMonetizationOn}
                    color={brandColor}
                  />
                }
              />
            }
            name="Total Bonus"
            value={`$${
              profile?.total_bonus == undefined ? "---" : profile?.total_bonus
            }`}
          />
          <MiniStatistics
            startContent={
              <IconBox
                w="30px"
                h="30px"
                bg={boxBg}
                icon={
                  <Icon w="22px" h="22px" as={MdPeople} color={brandColor} />
                }
              />
            }
            name="Total Referral Bonus"
            value={`$${
              profile?.referral_bonus == undefined
                ? "---"
                : profile?.referral_bonus
            }`}
          />
          <MiniStatistics
            startContent={
              <IconBox
                w="30px"
                h="30px"
                bg={boxBg}
                icon={
                  <Icon
                    w="22px"
                    h="22px"
                    as={MdTrendingUp}
                    color={brandColor}
                  />
                }
              />
            }
            name="Total Investment Plans"
            value={`${
              profile?.total_investment_plan == undefined
                ? "---"
                : profile?.total_investment_plan
            }`}
          />
          <MiniStatistics
            startContent={
              <IconBox
                w="30px"
                h="30px"
                bg={boxBg}
                icon={
                  <Icon w="22px" h="22px" as={MdBusiness} color={brandColor} />
                }
              />
            }
            name="Active Investment Plans"
            value={`${
              profile?.total_active_investment_plan == undefined
                ? "---"
                : profile?.total_active_investment_plan
            }`}
          />
          <MiniStatistics
            startContent={
              <IconBox
                w="30px"
                h="30px"
                bg={boxBg}
                icon={
                  <Icon
                    w="22px"
                    h="22px"
                    as={MdCreditCard}
                    color={brandColor}
                  />
                }
              />
            }
            name="Total Deposits"
            value={`$${
              profile?.total_deposit == undefined
                ? "---"
                : profile?.total_deposit
            }`}
          />
          
        </SimpleGrid>

        <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap="20px" mb="20px">
          <MiniStatistics
            startContent={
              <IconBox
                w="30px"
                h="30px"
                bg={boxBg}
                icon={
                  <Icon w="22px" h="22px" as={MdMoneyOff} color={brandColor} />
                }
              />
            }
            name="Account Type"
            value={`${
              profile?.account_type == undefined
                ? "---"
                : profile?.account_type.toUpperCase()
            }`}
          />
          <MiniStatistics
            startContent={
              <IconBox
                w="30px"
                h="30px"
                bg={boxBg}
                icon={
                  <Icon
                    w="22px"
                    h="22px"
                    as={MdCreditCard}
                    color={brandColor}
                  />
                }
              />
            }
            name="Trade Type"
            value={`${
              profile?.current_investment?.signal == undefined
                ? "---"
                : profile?.current_investment?.signal
            }`}
          />
          <MiniStatistics
            startContent={
              <IconBox
                w="30px"
                h="30px"
                bg={boxBg}
                icon={
                  <Icon w="22px" h="22px" as={MdMoneyOff} color={brandColor} />
                }
              />
            }
            name="Total Withdrawals"
            value={`$${
              profile?.total_withdrawal == undefined
                ? "---"
                : profile?.total_withdrawal
            }`}
          />
          <MiniStatistics
            startContent={
              <IconBox
                w="30px"
                h="30px"
                bg={boxBg}
                icon={
                  <Icon
                    w="22px"
                    h="22px"
                    as={MdCreditCard}
                    color={brandColor}
                  />
                }
              />
            }
            name="Account Status"
            value={`${
              profile?.kyc_status == undefined
                ? "---"
                : profile?.kyc_status == 0
                ? "Unverified"
                : profile?.kyc_status == 1
                ? "Pending Verification"
                : profile?.kyc_status == 2
                ? "Verified"
                : "---"
            }`}
          />
        </SimpleGrid>
        <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap="20px" mb="20px">
          <PieCard
            deposit={profile?.total_deposit}
            profits={profile?.total_earning}
          />
          <MiniCalendar h="100%" minW="100%" selectRange={false} />
        </SimpleGrid>
      </Box>
      <Box className="card p-3 overflow-scroll">
        <h2 className="fw-bold mb-2">Personal Trading Chart</h2>
        {/* <SimpleGrid columns={{ base: 1, md: 1, xl: 1 }}> */}
          {profile?.account_type == "Crypto Options" && (
            <TradingViewWidget symbol="BINANCE:BTCUSD" />
          )}
          {profile?.account_type == "Futures Options" && (
            <TradingViewWidget symbol="BINANCE:BTCUSDZ2023" />
          )}
          {profile?.account_type == "Forex Options" && (
            <TradingViewWidget symbol="FX:EURUSD" />
          )}
          {profile?.account_type == "Stock Options" && (
            <TradingViewWidget symbol="NASDAQ:TSLA" />
          )}
          {/* <TradingViewWidget symbol="BINANCE:BTCUSD" /> */}
          {/*  */}
        {/* </SimpleGrid> */}
      </Box>
    </>
  );
}
